import React from 'react'
import HeaderStyle1 from '../components/Header/HeaderStyle1'
import {FaUserAlt, FaFolder, FaRegCalendarAlt, FaLongArrowAltRight, FaStar} from "react-icons/fa"
import FooterStyle1 from '../components/Footer/FooterStyle1'
import { Link } from "gatsby"
import {Helmet} from "react-helmet"

const BlogList = (props) => {

    const {pathContext} = props
    const elements = []
    if (pathContext.posts !== undefined) {

        for (const post of pathContext.posts) {
            elements.push(
                <div className="blog-post" key={post.node.id}>
                    <div className="post-content">
                        <div className="left-part">
                            <div className="blog-date">
                                <span className="blog_date">
                                    <span className="date"><FaStar/></span><br/><span className="month">2020</span>
                                </span>
                                <div className="clr"></div>
                                <span className="blog_icon">
                                    <i className="icon-pencil" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                        <div className="right-part">
                            <div className="post-title">
                                <h3><Link to={post.node.frontmatter.path}>{post.node.frontmatter.title}</Link></h3>
                            </div>
                            <div className="post-text">
                                <p>{post.node.frontmatter.description}</p>
                            </div>
                            <div className="post-footer">
                                <div className="post-meta">
                                    <ul>
                                        <li><a href="/"><span><FaUserAlt /></span>湾区房产经纪 Jing</a></li>
                                        <li><a href="/blogs"><span><FaFolder/></span>Blog</a></li>
                                        <li><a href="/blogs"><span><FaRegCalendarAlt/></span>{post.node.frontmatter.date.substring(0,10)}</a></li>
                                    </ul>
                                </div>
                                <div className="read-more">
                                <Link to={post.node.frontmatter.path} className="btn-read-more">Read More <span><FaLongArrowAltRight/></span></Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
    }

    return (
        <>
           <HeaderStyle1 />
           <div className="application">
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>湾区买房攻略 | 湾区置业专家 湾区Top华人房产经纪 | 熟悉南湾、东湾、三谷市场 | Remax Accord 排名第一 经纪人 | 近12个月成交量超1亿美金</title>
                    <meta name="description" content="一支秉持诚信、负责、专业的湾区TOP 1% 房产经纪人团队，团队提供房屋上市、房屋贷款、房屋装修、staging、清洁等服务，熟悉南湾、东湾、三谷市场，全面考虑客户利益！" />
                    <meta name="Keywords" content="湾区买房攻略,湾区,三谷,华人房产经纪,南湾,东湾,湾区卖房,湾区卖房流程,listing,地产经纪,pleasanton,fremont,cupertino,san jose,mountain view,dublin,palo alto,melo park,livermore,dublin,danville"></meta>
                    <meta name="author" content="Jing Xue" />
                    <link rel="canonical" href="https://www.jingxuehomes.com" />
                </Helmet>
                <script type="application/ld+json">{`
                {
                    "@context": "https://schema.org",
                    "@type": "FAQPage",
                    "mainEntity": [
                        {
                            "@type": "Question",
                            "name": "如何判断地基是否下沉,地基下沉的表现?",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "<p>地基问题是买房当中需要特别特别注意的，因为地基一旦出问题，维修的费用会相当贵（几万块到十几万乃至更多）而且如果地基问题不及时修复，房子的状况可能会越来越糟糕，甚至会倾斜垮掉。<a href=https://www.jingxuehomes.com/foundation-issue> 点击查看 </a></p>"
                            }
                        },{
                            "@type": "Question",
                            "name": "湾区买房一定要知道的噪音问题！",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "<p>车辆和飞机的轰鸣声，会有害于人体健康。居民住宅长期受噪音污染影响，最明显的影响就是睡眠质量下降，导致人长期得不到充分休息，使人烦躁焦虑甚至影响儿童大脑发育。<a href=https://www.jingxuehomes.com/noise> 点击查看 </a></p>"
                            }
                        },{
                            "@type": "Question",
                            "name": "房子下周就CLOSE了，产权持有方式到底该怎么选？",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "<p>在购房交易的过程中，买家们都会收到交易公司发来的这样一张表格，加州房地产产权的方式种类比较多，今天Jing跟大家来一起详细聊一聊每一种的优缺点，这张表格我们到底应该怎么勾选？<a href=https://www.jingxuehomes.com/equity-right> 点击查看 </a></p>"
                            }
                        },{
                            "@type": "Question",
                            "name": "SB8、SB9、SB10号法案通过，对我们湾区的房地产会带来什么样的影响？",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "<p>此前，纽森在一份声明中表示，希望为加州创建更多经济适用房，缓解日益膨胀的住宅负担力危机，让大家还能有一个“加州梦”。就在上周五纽约森正式签署了SB8、SB9、SB10三项法案使之成为法律，有效地结束了全州大多数社区传统的单一家庭分区限制。来跟大家一起看看这三个法案到底怎么回事?对我们到底有什么影响呢？<a href=https://www.jingxuehomes.com/california-sb10-planning-zoning> 点击查看 </a></p>"
                            }
                        },{
                            "@type": "Question",
                            "name": "房屋有白蚁？！怎么办？",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "<p>不论是地下白蚁还是干木白蚁，处理起来都又贵又麻烦。咱们最好防患于未然，每年都请有执照的白蚁防控专家对房子做一个termite检查。这样的项目非常检查，一般检查时间30-50分钟，大概100左右即可搞定。会在房子外围喷洒药水。作为我们持有房产的小伙伴，平时也要随时留心，如果发现任何迹象，一定要尽快的联系专业termite公司处理，以免感染扩大，造成房屋结构的损坏。<a href=https://www.jingxuehomes.com/termite> 点击查看 </a></p>"
                            }
                        },{
                            "@type": "Question",
                            "name": "想换房，先买再卖？还是先卖再买？",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "<p>大部分“二次购房者”会因经济因素，从而选择先卖掉手头的房屋，再来购入新房。卖掉旧房后手上就会有更多的首付以及更高的贷款额度。<a href=https://www.jingxuehomes.com/buy-or-sell> 点击查看 </a></p>"
                            }
                        },{
                            "@type": "Question",
                            "name": "美国买新房必读 9件该知道的事",
                            "acceptedAnswer": {
                                "@type": "Answer",
                                "text": "<p>购买新房与二手房有不同的游戏规则，简单的说，二手房的卖家一般是屋主，买家可以与其讨价还价；而买新房，卖家一般是开发商、建商，价格大多是公订价 （部分楼盘还是可以讲价！！）。<a href=https://www.jingxuehomes.com/bay-area-new-home> 点击查看 </a></p>"
                            }
                        }
                    ]
                }
                `}</script>
            </div>
           <section className="pad80">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">

                            {elements}

                        </div>
                    </div>
                </div>
            </section>


            <FooterStyle1/>


        </>
    )
}

export default BlogList
